import React, { useState, useRef, useEffect } from "react";
import { SQuestions } from "./squestions";
import questionsData from "../data/questions.json";
import {
  FaArrowLeft,
  FaCheck,
  FaTimes,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import Modal from "./modal";
import "./survey.css";

export const Survey = () => {
  const url = "https://api.letbabytalk.com/get_survey_result";
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [answers, setAnswers] = useState([]);
  const [userName, setUserName] = useState("");
  const [expandedQuestions, setExpandedQuestions] = useState({});
  const [theResults, setTheResults] = useState([]);
  const [theCorrect, setTheCorrect] = useState(0);
  const audioRefs = useRef([]);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (currentQuestionIndex == -1) {
      document.body.style.overflow = "hidden";
    }
    if (currentQuestionIndex == questionsData.length) {
      document.querySelector(".survey-container").style.background =
        "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)";
      // document.body.style.background =
      //   "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)";
      document.querySelector(".survey-title").style.color = "white";
      document.querySelector(".survey-container").style.alignItems = "center";
      document.querySelector(".survey-container").style.height = "auto";
    }

    return () => {
      document.body.style.background =
        "linear-gradient(to right, #5ca9fb 0%, #6372ff 100%)";
      document.body.style.overflow = "auto";
      document.querySelector(".survey-container").style.height = "100vh";
      document.querySelector(".survey-container").style.alignItems = "left";
      // document.body.style.background = "white";

      if (currentQuestionIndex < questionsData.length) {
        // document.querySelector(".survey-container").style.background = "white";
        // document.querySelector(".survey-title").style.color = "black";
        // document.querySelector(".body").style.background = "white";
      }
    };
  }, [currentQuestionIndex]);

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const toggleExpand = (index) => {
    setExpandedQuestions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const onAudioPlay = (key) => {
    Object.entries(audioRefs.current).forEach(([refKey, audio]) => {
      if (refKey !== key && audio && !audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  };

  const handleRestart = () => {
    setCurrentQuestionIndex(-1);
    setAnswers([]);
    setExpandedQuestions({});
    setUserName("");
    setSelectedAnswer("");
  };

  const handleAnswer = (selectedOption) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[currentQuestionIndex] = {
        question: currentQuestionIndex + 1,
        userAnswer: selectedOption,
      };
      console.log(updatedAnswers);
      console.log(currentQuestionIndex);
      if (currentQuestionIndex === questionsData.length - 1) {
        const finalResults = updatedAnswers.map((answer, index) => {
          const correctAnswer = questionsData[index].trueAnswer;
          const isCorrect = answer.userAnswer === correctAnswer;
          return { ...answer, isCorrect };
        });
        console.log(finalResults);
        setTheResults(finalResults);

        const totalCorrect = finalResults.filter(
          (result) => result.isCorrect
        ).length;
        setTheCorrect(totalCorrect);
        const accuracy = ((totalCorrect / questionsData.length) * 100).toFixed(
          2
        );

        const payload = {
          userName,
          selectedAnswer,
          totalCorrect,
          accuracy,
          results: finalResults,
        };

        sendResultsToBackend(payload);
      }

      return updatedAnswers;
    });

    if (currentQuestionIndex < questionsData.length) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const sendResultsToBackend = async (payload) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        console.error("Failed to send results:", response.statusText);
      }
    } catch (err) {
      console.error("Error sending results to backend:", err);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleStartSurvey = () => {
    if (userName.trim() === "") {
      alert("Please enter your name to start the survey.");
    } else if (selectedAnswer === "") {
      alert("Please select an answer to start the survey.");
    } else {
      // setCurrentQuestionIndex(0);
      setShowModal(true);
    }
  };

  const question = questionsData[currentQuestionIndex];

  return (
    <div className="survey-container">
      <h1 className="survey-title">Can You Identify Baby Cries?</h1>
      {currentQuestionIndex === -1 && (
        <>
          <h3 className="survey-subtitle">
            🌟Welcome to our survey! <br />
            🤔Can you uncover the secrets behind a cry? <br />
            🎧Put your skills to the test!
          </h3>
        </>
      )}

      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false);
            setCurrentQuestionIndex(0);
          }}
        />
      )}

      {currentQuestionIndex === -1 ? (
        <div className="survey-start">
          <h3>Enter your name to start the survey:</h3>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="survey-input"
            placeholder="Your Name"
          />
          <h3>Have you ever had experience taking care of a baby?</h3>
          <div className="survey-dropdown">
            <select value={selectedAnswer} onChange={handleAnswerChange}>
              <option value="" disabled>
                Select an answer
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <button onClick={handleStartSurvey} className="survey-start-button">
            Start
          </button>
        </div>
      ) : currentQuestionIndex < questionsData.length ? (
        <div>
          <div className="survey-navigation">
            {currentQuestionIndex > 0 ? (
              <button
                onClick={handlePrevious}
                className="survey-previous-button"
              >
                <FaArrowLeft
                  className="survey-icon"
                  style={{ marginRight: "6px" }}
                />{" "}
                Previous
              </button>
            ) : (
              <div className="survey-placeholder"></div>
            )}
            <span className="survey-question-number">
              Question {currentQuestionIndex + 1} of {questionsData.length}
            </span>
          </div>
          <SQuestions
            classAAudios={question.classAAudios}
            classBAudios={question.classBAudios}
            testAudio={question.testAudio}
            options={question.options}
            onAnswer={handleAnswer}
            currentQuestionIndex={currentQuestionIndex}
            audioRefs={audioRefs}
            onAudioPlay={onAudioPlay}
            selectedAnswer={answers[currentQuestionIndex]?.userAnswer || ""}
          />
        </div>
      ) : (
        <div className="survey-results">
          {/* <h3>Survey Results</h3>
          <h5>Thank you, {userName}, for completing the survey!</h5>
          <h4>
            You got {theCorrect} out of {questionsData.length} correct.
          </h4> */}
          <div className="survey-results-card">
            <h2 className="survey-results-title">🎉 Survey Completed! 🎉</h2>
            <p className="survey-thank-you">
              Thank you <strong>{userName}</strong>, for completing the survey!
            </p>

            <div
              className="survey-score"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                textAlign: "center",
              }}
            >
              <p className="score-text" style={{ margin: 0 }}>
                You got
              </p>
              <p className="score-number" style={{ margin: 0 }}>
                {theCorrect} / {questionsData.length}
              </p>
              <p className="score-text" style={{ margin: 0 }}>
                correct 🎯
              </p>
            </div>

            <ul>
              {theResults.map((result, index) => (
                <li key={index} className="result-item">
                  <div
                    className="result-header"
                    onClick={() => toggleExpand(index)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "10px",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <span>
                      Question {result.question}: {result.userAnswer}{" "}
                      {result.isCorrect ? (
                        <FaCheck
                          className="correct-icon"
                          style={{ color: "green" }}
                        />
                      ) : (
                        <FaTimes
                          className="wrong-icon"
                          style={{ color: "red" }}
                        />
                      )}
                    </span>
                    {expandedQuestions[index] ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </div>

                  {expandedQuestions[index] && (
                    <div
                      className={`result-details ${
                        expandedQuestions[index] ? "expanded" : ""
                      }`}
                      style={{ padding: "10px" }}
                    >
                      <p style={{ fontSize: "1.2rem", fontFamily: "Arial" }}>
                        Option 1: {questionsData[index].options[0]}
                      </p>
                      {questionsData[index].classAAudios.map((audio, idx) => (
                        <div key={`${index}-classA-${idx}`}>
                          {/* <p>Audio {idx + 1}:</p> */}
                          <audio
                            style={{
                              width: "80%",
                              display: "block",
                              margin: "3 auto",
                              marginBottom: "10px",
                            }}
                            controls
                            controlsList="nodownload"
                            ref={(el) =>
                              (audioRefs.current[`${index}-classA-${idx}`] = el)
                            }
                            onPlay={() => onAudioPlay(`${index}-classA-${idx}`)}
                          >
                            <source src={audio} type="audio/wav" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ))}

                      <p style={{ fontSize: "1.2rem", fontFamily: "Arial" }}>
                        Option 2: {questionsData[index].options[1]}
                      </p>
                      {questionsData[index].classBAudios.map((audio, idx) => (
                        <div key={`${index}-classB-${idx}`}>
                          {/* <p>Audio {idx + 1}:</p> */}
                          <audio
                            style={{
                              width: "80%",
                              display: "block",
                              margin: "3 auto",
                              marginBottom: "10px",
                            }}
                            controls
                            controlsList="nodownload"
                            ref={(el) =>
                              (audioRefs.current[`${index}-classB-${idx}`] = el)
                            }
                            onPlay={() => onAudioPlay(`${index}-classB-${idx}`)}
                          >
                            <source src={audio} type="audio/mp3" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      ))}

                      <p style={{ fontSize: "1.2rem", fontFamily: "Arial" }}>
                        Question:
                      </p>
                      <audio
                        style={{
                          width: "80%",
                          display: "block",
                          margin: "5 auto",
                          marginBottom: "15px",
                        }}
                        controls
                        controlsList="nodownload"
                        ref={(el) => (audioRefs.current[`${index}-test`] = el)}
                        onPlay={() => onAudioPlay(`${index}-test`)}
                      >
                        <source
                          src={questionsData[index].testAudio}
                          type="audio/mp3"
                        />
                        Your browser does not support the audio element.
                      </audio>

                      <p style={{ fontSize: "1.2rem", fontFamily: "Arial" }}>
                        Correct Answer: {questionsData[index].trueAnswer}
                      </p>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <button className="survey-restart-button" onClick={handleRestart}>
            Restart
          </button>
        </div>
      )}
    </div>
  );
};
