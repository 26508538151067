import React, { useState } from "react";
import "./squestions.css";

export const SQuestions = ({
  classAAudios,
  classBAudios,
  testAudio,
  options,
  onAnswer,
  currentQuestionIndex,
  audioRefs,
  onAudioPlay,
  selectedAnswer,
}) => {
  console.log("answer", selectedAnswer);
  console.log(selectedAnswer == options[0]);
  const [selectedOption, setSelectedOption] = useState(selectedAnswer);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (onAnswer) {
      onAnswer(option);
    }
    setSelectedOption(null);
  };

  return (
    <div className="squestions-container">
      <h1 className="squestions-title">🎧Listen to the reference audios</h1>

      <div className="survey-audios">
        <div className="audio-column">
          <h3>{options[0]}</h3>
          {classAAudios.map((audio, index) => (
            <div
              key={`${currentQuestionIndex}-classA-${index}`}
              className="audio-wrapper"
            >
              <audio
                controls
                controlsList="nodownload"
                ref={(el) =>
                  (audioRefs.current[
                    `${currentQuestionIndex}-classA-${index}`
                  ] = el)
                }
                onPlay={() =>
                  onAudioPlay(`${currentQuestionIndex}-classA-${index}`)
                }
              >
                <source src={audio} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ))}
        </div>
        <div className="audio-column">
          <h3>{options[1]}</h3>
          {classBAudios.map((audio, index) => (
            <div
              key={`${currentQuestionIndex}-classB-${index}`}
              className="audio-wrapper"
            >
              <audio
                controls
                controlsList="nodownload"
                ref={(el) =>
                  (audioRefs.current[
                    `${currentQuestionIndex}-classB-${index}`
                  ] = el)
                }
                onPlay={() =>
                  onAudioPlay(`${currentQuestionIndex}-classB-${index}`)
                }
              >
                <source src={audio} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </div>
          ))}
        </div>
      </div>

      <div className="test-audio-section">
        <h1 className="squestions-title">
          🧐Test your skills — can you identify the new audio?
        </h1>
        <audio
          key={`${currentQuestionIndex}-test`}
          controls
          controlsList="nodownload"
          ref={(el) =>
            (audioRefs.current[`${currentQuestionIndex}-test-q`] = el)
          }
          onPlay={() => onAudioPlay(`${currentQuestionIndex}-test-q`)}
        >
          <source src={testAudio} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      </div>

      <div className="button-section">
        <button
          onClick={() => handleOptionChange(options[0])}
          className={`option-button ${
            selectedAnswer == options[0] ? "selected" : ""
          } option-a`}
        >
          {options[0]}
        </button>
        <button
          onClick={() => handleOptionChange("Not sure")}
          className={`option-button ${
            selectedAnswer == "Not sure" ? "selected" : ""
          } option-c`}
        >
          Not sure
        </button>
        <button
          onClick={() => handleOptionChange(options[1])}
          className={`option-button ${
            selectedAnswer == options[1] ? "selected" : ""
          } option-b`}
        >
          {options[1]}
        </button>
      </div>

      {selectedOption && (
        <div className="selected-option">You selected: {selectedOption}</div>
      )}
    </div>
  );
};
