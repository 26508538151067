import React from "react";
import "./modal.css"; // Ensure to create a CSS file for styling

const Modal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">📢 Instructions</h2>
        <ul className="modal-steps">
          <li>
            1. Please first listen to the reference audios at the top, each labeled with a reason for the baby's cry.
          </li>
          <li>2. Play the new audio at the bottom.</li>
          <li>
            3. Choose the reason that best matches the new cry.
          </li>
          {/* <li>
            1. You will first listen to a set of reference audios, each labeled
            with a reason for the baby's cry.
          </li>
          <li>2. After listening, a new audio will be played.</li>
          <li>
            3. Based on what you’ve heard, simply choose the label that best
            matches the new cry.
          </li> */}
        </ul>
        <button className="modal-button" onClick={onClose}>
          🚀 Let's Start
        </button>
      </div>
    </div>
  );
};

export default Modal;
